import Router, { useRouter } from 'next/router';
import useSWR from 'swr';
import { useEffect, useState } from 'react';
import { AnalyticsBrowser } from '@june-so/analytics-next';
import { useStoreState, useStoreActions } from 'easy-peasy';

import { StoreModel } from '../store/model';

const fetchUser = async url => {
  const res = await fetch(url);
  const data = await res.json();
  return { user: data?.user || null };
};

export function useUser() {
  const { data, error } = useSWR('/api/user', fetchUser);
  if (error) {
    Router.push('/login');
    return;
  }
  if (!data) return { loading: true };
  return data.user;
}

export function useJune(writeKey) {
  const [analytics, setAnalytics] = useState(undefined);

  useEffect(() => {
    const loadAnalytics = async () => {
      let response = AnalyticsBrowser.load({
        writeKey,
      });
      setAnalytics(response);
    };
    loadAnalytics();
  }, [writeKey]);

  return analytics;
}

export const useKeyboardNavigation = ({
  sections,
  activeSection,
  buildingId,
  toggleToPrevSection,
  toggleToNextSection,
  addNewItem,
  clearSearch,
  searchTerm,
  focusSearch,
  searchInputRef,
}) => {
  const router = useRouter();

  const openModal = useStoreState<StoreModel>(state => state.modals.openModal);
  const setOpenModal = useStoreActions<StoreModel>(
    actions => actions.modals.setOpenModal,
  );

  useEffect(() => {
    const handleKeyDown = event => {
      if (event.key === 'Escape') {
        if (document.activeElement === searchInputRef.current || searchTerm) {
          event.preventDefault();
          clearSearch();
          return;
        }
      }

      if (
        event.target.tagName === 'INPUT' ||
        event.target.tagName === 'TEXTAREA' ||
        event.target.tagName === 'SELECT' ||
        event.target.isContentEditable
      ) {
        return;
      }

      switch (event.key) {
        case 'ArrowLeft':
          event.preventDefault();
          toggleToPrevSection();
          break;

        case 'ArrowRight':
          event.preventDefault();
          toggleToNextSection();
          break;

        default:
          switch (event.key.toLowerCase()) {
            case 'e':
              event.preventDefault();
              setOpenModal({ type: 'Update Building', id: buildingId });
            case 'a':
              event.preventDefault();
              navigateToSection('areas');
              break;
            case 'p':
              event.preventDefault();
              navigateToSection('projects');
              break;
            case 'i':
              event.preventDefault();
              navigateToSection('issues');
              break;
            case 'd':
              event.preventDefault();
              router.push('/d');
              break;
            case 'n': // Alt+N as alternative for Issues
              event.preventDefault();
              navigateToSection('inventory');
              break;
            case 'q':
              event.preventDefault();
              addNewItem();
              break;
            case '/':
              event.preventDefault();
              focusSearch();
              break;
            case 'm':
              event.preventDefault();
              setOpenModal({ type: 'Suggest Issues', id: 'new' });
              break;
            case 'r':
              event.preventDefault();
              setOpenModal({ type: 'Reports', id: '0' });
              break;
          }
          break;
      }
    };

    const navigateToSection = sectionName => {
      if (sectionName === activeSection) return;

      if (sectionName === 'Areas') {
        router.push(`/b/${buildingId}`);
      } else {
        router.push(`/b/${buildingId}?active=${sectionName.toLowerCase()}`);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    // Cleanup
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [
    activeSection,
    buildingId,
    router,
    sections,
    toggleToPrevSection,
    toggleToNextSection,
    addNewItem,
    clearSearch,
    searchTerm,
    openModal,
    setOpenModal,
  ]);
};
