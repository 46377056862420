import ReactModal from 'react-modal';
import { useEffect, useState, useContext, useMemo } from 'react';
import { useRouter } from 'next/router';
import { ApolloProvider } from '@apollo/client';
import { StoreProvider } from 'easy-peasy';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { Analytics } from '@vercel/analytics/react';

import store from '../store';
import { UserProvider } from '../components/UserProvider';
import { useJune } from '../lib/hooks';
import { UserContext } from '../lib/UserContext';
import { LoadingProgress } from '../components/LoadingProgress';

import '../styles/globals.css';
import 'nprogress/nprogress.css';

ReactModal.setAppElement('body');

const theme = {
  brandYellow: '#ffe76b',
  statusRed: '#FF5323',
  borderRadius: '4px',
  darkGray: '#333',
  lightGray: '#f2f2f2',
  appFontFamily: '"Roboto", sans-serif',
  appBodyFontSize: '14px',
};

const GlobalStyle = createGlobalStyle`
  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker__input-container {
    display: flex;
    align-items: center;
  }


  .react-datepicker__close-icon::after {
    background-color: transparent;
  }
`;

const useAuth = () => useContext(UserContext);

/**
 * Wraps the application with ApolloProvider and provides authentication functionality.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The child components to be wrapped.
 * @returns {ReactNode} The wrapped components with ApolloProvider.
 */
const ApolloProviderWrapper = ({ children }) => {
  const { token, userLoading } = useAuth();

  const router = useRouter();
  const isLandingPage = router.pathname === '/';
  const isLoginPage = router.pathname === '/login';

  const client = useMemo(() => {
    const httpLink = createHttpLink({
      uri: 'https://winning-tomcat-19.hasura.app/v1/graphql',
      credentials: 'include',
    });

    const authLink = setContext(async (_, { headers }) => {
      return {
        headers: {
          ...headers,
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: token ? `Bearer ${token}` : '',
        },
      };
    });

    return new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache(),
      connectToDevTools: true,
    });
  }, [token]);

  if (userLoading && !isLandingPage && !isLoginPage) {
    return null;
  }

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

const App = ({ Component, pageProps }) => {
  const router = useRouter();
  const analytics = useJune(process.env.NEXT_PUBLIC_JUNE_WRITE_KEY);

  useEffect(() => {
    const handleRouteChange = (url, { shallow }) => {
      if (analytics) analytics.page(url);
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [analytics]);

  return (
    <ThemeProvider theme={theme}>
      <UserProvider>
        <StoreProvider store={store}>
          <ApolloProviderWrapper>
            <GlobalStyle />
            <LoadingProgress />
            <Component {...pageProps} />
            <Analytics />
          </ApolloProviderWrapper>
        </StoreProvider>
      </UserProvider>
    </ThemeProvider>
  );
};

export default App;
